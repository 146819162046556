:root {
    --color-bg-primary: #000;
    --color-bg-secondary: #fff;
    --color-bg-tertiary: #54BAB9;
    --color-bg-quaternary: #479190;
    --color-bg-quinary: #262626;
    --color-text-primary: #000;
    --color-text-secondary: #fff;
    --color-text-tertiary: #54BAB9;
    --color-seperator: #949494;

}