@import '../00-global/color.scss';

.ot-section {
    width: 100%;

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: var(--color-text-secondary);
        
        h1 {
            margin-bottom: 0;
            font-size: 34px;
            
        }
        p {
            color: var(--color-text-secondary);}
            a {
                color: var(--color-text-tertiary);
                text-decoration: none;
                font-weight: 700;
                &:hover {
                    text-decoration: underline;
                }
            }
            
    }
    &__headline {
        font-size: 24px;
        font-weight: 700;
        color: var(--color-text-secondary);
        text-align: center;
    }
    
    &__description {
        font-size: 16px;
        font-weight: 400;
        color: var(--color-text-secondary);
        width: 80%;
        text-align: center;
    }
    &__content {
        width: 90%;
        display: flex;
        justify-content: center;
        gap: 1.5rem;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &__message {
        font-size: 16px;
        font-weight: 400;
        color: var(--color-text-tertiary);
        text-align: center;
        margin-top: 1rem;
    }
}
.ot-data-protection {
    max-width: 80%;
            margin: 5px auto;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            font-family: Arial, sans-serif;
}

form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 18px;
}

.ot-checkbox {
    transform: scale(1.5); /* Hier kannst du den Wert anpassen, um die Checkbox zu vergrößern */
    margin-right: 10px; /* Abstand zwischen Checkbox und Label */
    vertical-align: middle; /* Macht die Checkbox und das Label horizontal ausgerichtet */
  }
  
  /* Style für das Label */
  .ot-checkbox-label {
    display: inline-flex;
    align-items: center; /* Zentriert das Label vertikal mit der Checkbox */
    font-size: 16px; /* Falls du die Schriftgröße des Labels anpassen möchtest */
  }