@import '../00-global/color.scss';

@mixin button-primary {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 0.5rem 1.5rem;
    background-color: var(--color-bg-tertiary);
    border: none;
    border-radius: 70px;
    font-size: 14px;
    line-height: 21px;
    text-decoration: none;
    color: var(--color-text-secondary);
    transition: all 0.3s linear;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

    &:hover {
        background-color: var(--color-bg-quaternary);
    }

    &__text {
        text-transform: uppercase;
        font-weight: 700;
        cursor: pointer;
       
    }
}