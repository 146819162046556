@import '../00-global/color.scss';
@import '../00-global/grid.scss';
.ot-footer {
    border-top: 1px solid var(--color-seperator);
    background-color: var(--color-bg-primary);
    padding-bottom: 2rem;
    color: var(--color-bg-secondary);

    &__container {
        padding-left: 40px;
        padding-right: 40px;
    }

    &__list {
        display: flex;
        gap: 50px;

        .ot-footer__list {
            display: block;
        } 
    }

    @media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
        &__list {
            flex-direction: column;
            gap: 20px; // Weniger Abstand zwischen den Elementen
          }
      }

    &__list-item {
        margin-bottom: 1rem;
        flex: 1 0 auto;
    }

    &__link {
        color: var(--color-bg-secondary);
        font-size: 16px;
        line-height: 18.4px;
    }
}