@import '../00-global/color.scss';

.ot-input {
    background-color: var(--color-bg-quinary);
    padding: 0.5rem 1.5rem;
    border: none;
    border-bottom: 2px solid var(--color-bg-tertiary);
    font-size: 14px;
    line-height: 21px;
    color: var(--color-text-secondary);
    outline: none;
}
