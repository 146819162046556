@import '../00-global/color.scss';
@import '../00-global/grid.scss';
@import '../04-mixins/typography.scss';

.ot-header {
  position: fixed;
  background-color: var(--color-bg-primary);
  width: 100%;
  top: 0;
  left: 0;
  height: 90px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-seperator);

  &__container { 
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  &__logo {
    color: #fff;
    @include font-heading-h1;

    span {
      color: var(--color-text-tertiary);
    }
  }
}