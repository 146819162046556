@import '../00-global/color.scss';

.ot-content-block {
    flex: 1 1 calc(33.33% - 1.5rem);
    max-width: calc(33.33% - 1.5rem);
    background-color: var(--color-bg-quinary);
    color: var(--color-bg-secondary);

    &__container {
       display: flex;
        flex-direction: column; 
        padding: 2rem;
        text-align: left;

        .ot-content-image {
            width: 80px;
            height: 80px;
            background-color: var(--color-bg-tertiary);
            border-radius: 50%;
            align-self: self-end;
            margin-bottom: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            
        }
    }
}

@media (max-width: 992px) {
    .ot-content-block {
        flex: 1 1 calc(50% - 1rem);
        max-width: calc(50% - 1rem);
    }
}

@media (max-width: 576px) {
    .ot-content-block {
        flex: 1 1 100%;
        max-width: 100%;
    }
}