$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

:root {
    --grid-xs: #{map-get($grid-breakpoints, 'xs')};
    --grid-sm: #{map-get($grid-breakpoints, 'sm')};
    --grid-md: #{map-get($grid-breakpoints, 'md')};
    --grid-lg: #{map-get($grid-breakpoints, 'lg')};
    --grid-xl: #{map-get($grid-breakpoints, 'xl')};
}