.renderPage {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.main {
    padding-top: 90px;
    flex-grow: 1;
    background-color: black;
}

a {
    text-decoration: none;
    cursor: pointer;
}

ul {
    list-style-type: none;
    padding: 0;
}

.ot-text {
    color: white;
    width: 80%;

   

}